<template>
<div>
    <!-- Mis cuenta-->
    <v-row justify="center" class="mb-0 mt-2">
        <v-col cols="12" md="4" class="pa-0 pt-4" align="center" width="100%">
            <!-- Mi perfil-->
            <v-card width="90%" class="pb-7">
                <v-card-title>
                    <v-col>
                        <v-row justify="center">
                            <v-avatar size="100" class="avatar_img">
                                <v-img :src=" `${profile_image_path}` "></v-img>
                                
                            </v-avatar>
                        </v-row>
                        <v-row justify="center">
                            <p v-if="($store.state.user.nombre!=null)">{{$store.state.user.nombre + " " + $store.state.user.apellido_p+ " " + $store.state.user.apellido_m}}</p>
                            <p v-else>Completa tu perfil...
                                
                            </p>
                        </v-row>
                    </v-col>
                </v-card-title>
                <v-sheet class="mr-4 ml-4 pb-5">
                    <v-card-text>
                        
                        <p>
                            <v-icon class="pr-2">mdi-email</v-icon>{{$store.state.user.username }}
                        </p>
                        <p>
                            <v-icon class="pr-2">mdi-phone</v-icon>
                            <span v-if="$store.state.user.codigo_pais">{{$store.state.user.codigo_pais ?? ""+" " + $store.state.user.telefono_movil ?? ""}}</span>
                        </p>
                        <p>
                            <v-icon class="pr-2">mdi-cake-variant</v-icon>
                            <span v-if="$store.state.user.fecha_de_nacimiento">{{$store.state.user.fecha_de_nacimiento | date_format_short}}</span>
                        </p>

                    </v-card-text>
                    <v-card-actions>
                        <v-row align="center" justify="center" class="pa-4">
                           
                            <v-btn small  width="90%" class="eps_green" @click="open_edit_profile()">
                                <v-icon left>mdi-pencil</v-icon>
                                Editar perfil...
                            </v-btn>
                            <v-btn small width="90%"  class="eps_green" @click="show_reset_password=true">
                                <v-icon left>mdi-lock</v-icon>
                                Cambiar contraseña...</v-btn>                       
                        
                            <v-btn small width="90%"  class="eps_red" @click="show_delete_account=true">
                                <v-icon left>mdi-delete</v-icon>
                                Eliminar mi cuenta...</v-btn>
                        </v-row>
                    </v-card-actions>
                </v-sheet>
            </v-card>
        </v-col>
    </v-row>
 <!-- Alerta -->
 <v-snackbar timeout="8000" v-model="alert">
    <v-container fluid class="pa-2 ma-2" :class="alert_color">{{alert_title}}</v-container>
    <v-container grid-list-xs>
        {{alert_message}}
    </v-container>
    <v-row justify="end">
        <v-btn text @click="alert=false">Ok</v-btn>
    </v-row>
</v-snackbar>
    <!-- Editar el perfil del usuario -->
    <v-dialog v-model="show_edit_profile">
        <v-card>
            <v-card-title primary-title>
                Editar mi perfil:
            </v-card-title>
            <v-card-text>
                <v-form>
                    <v-text-field v-model="name" name="nombre" label="Nombre" prepend-icon="mdi-account"></v-text-field>
                    <v-text-field v-model="last_name_p" name="last_name" label="Apellido paterno" class="pl-8"></v-text-field>
                    <v-text-field v-model="last_name_m" name="last_name_m" label="Apellido materno" class="pl-8"></v-text-field>
                    <v-row align="center" class="mt-1">
                        <v-col cols="auto">
                            <v-icon>mdi-phone</v-icon>
                        </v-col>
                        <v-col cols="auto">
                            <vue-country-code class="mb-3" enabledCountryCode @onSelect="onSelect" :preferredCountries="['mx', 'us', 'ca']">
                            </vue-country-code>
                        </v-col>
                        <v-col>
                            <v-text-field v-model="telefono_movil" name="telefono_movil" label="Teléfono movil" class="mb-0"></v-text-field>
                        </v-col>
                    </v-row>

                    <v-file-input v-model="profile_pic" prepend-icon="mdi-camera" label="Fotografía" show-size counter accept="image/x-png,image/gif,image/jpeg"></v-file-input>
                    <v-menu v-model="menu" :close-on-content-click="false" offset-y min-width="290px">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field prepend-icon="mdi-calendar" type="text" v-model="birthdate" readonly v-bind="attrs" v-on="on" label="Fecha de nacimiento" />
                        </template>
                        <v-date-picker v-model="birthdate" @change="menu = false"></v-date-picker>
                    </v-menu>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="eps_red" @click="show_edit_profile=false">Cancelar</v-btn>
                <v-btn :loading="saving_user_profile" class="eps_green" @click="save_user_profile()">Guardar...</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <!-- Cambiar contraseña -->
    <v-dialog v-model="show_reset_password" scrollable  max-width="90%" transition="dialog-transition">
        <v-card>
            <v-card-title primary-title>
                Cambiar contraseña
            </v-card-title>
            <v-card-text>
                Confirma tu correo y haz click en "Cambiar contraseña", te enviaremos un correo con instrucciones.
            </v-card-text>
            <v-card-text>
                <v-text-field v-model="username" name="Correo" label="Usuario" id="id"></v-text-field>
            </v-card-text>
            <v-card-actions class="mb-6">
                <v-col>
                <v-row><p>{{recover_message}}</p></v-row>
                <v-row>
                    
                    <v-btn small @click="recover_password()" class="eps_green" dark>Cambiar contraseña...</v-btn>
                    <v-btn small @click="show_reset_password=false" class="eps_red">Cancelar</v-btn>
                </v-row>
            </v-col>
            </v-card-actions>
        </v-card>

    </v-dialog>
    <!-- Eliminar contraseña -->
    <v-dialog v-model="show_delete_account" scrollable  max-width="90%" transition="dialog-transition">
        <v-card>
            <v-card-title primary-title>
                ¡Es una pena que te vayas!
            </v-card-title>
            <v-card-text>
                Al hacer click en "Eliminar mi cuenta" tus datos personales y tu cuenta en EPS+ se eliminarán de nuestro sistema.
            </v-card-text>
            
            <v-card-actions class="mb-6">
                <v-col>
                <v-row><p>{{recover_message}}</p></v-row>
                <v-row>
                    <v-spacer></v-spacer>
                <v-btn small @click="show_delete_account=false" class="eps_red">Cancelar</v-btn>
                <v-btn small @click="delete_account()" class="eps_green" dark>Eliminar mi cuenta</v-btn>
                </v-row>
            </v-col>
            </v-card-actions>
        </v-card>

    </v-dialog>
</div>
</template>

<script>
import axios from "axios"
import supabaseMixin from '../mixins/SupabaseMixin.js'

export default {
    name: "MiCuenta",
    mixins: [supabaseMixin],
    data: () => ({
        show_delete_account:false,
        alert_message:"",
        recover_message:"",
        alert_color:"",
        username:"",
        alert_title:"",
        alert:false,
        show_reset_password:false,
        country_code: "",
        show_edit_profile: false,
        name: "",
        last_name_p: "",
        last_name_m: "",
        menu: false,
        telefono_movil: "",
        saving_user_profile: false,
        profile_pic: null,
        birthdate: null,
        apiurl: process.env.VUE_APP_ROOT_API
    }),
    methods: {
        async logout() {
            this.$store.state.loggedin = false
            localStorage.clear()
            this.$store.state.menu_inferior = 0
        },
        async delete_account(){
            let response = await axios.post(this.apiurl + '/api/clients/delete_account', {
                email: this.$store.state.user.username,
            })
            if (response.data.status=="ok"){
                this.logout()
            }
        },
        async recover_password() {
            if(this.username == ""){
                this.alert_color="red"
                this.alert_title="Error"
                this.alert_message="Ingrese el correo con el que ingresas a EPS+"
                this.alert=true
                return
            }else{
            let recover = await axios.post(this.apiurl + '/api/clients/recover_password', {
                username: this.username,
            })
            if (recover.data.status == "ok") {
                this.alert = true
                this.alert_color="green"
                this.alert_title="Revisa tu email..."
                this.alert_message = "Te enviamos un correo con instrucciones para reestablecer tu contraseña..."
                this.show_reset_password = false
            } else if (recover.data.status == "error") {
                this.recover_message = recover.data.message
            }
        }
        },
        onSelect({
            name,
            iso2,
            dialCode
        }) {
            this.country_code = dialCode
            console.log(name, iso2, dialCode);
        },
        open_edit_profile() {
            this.show_edit_profile = true
            this.name = this.$store.state.user.nombre
            this.last_name_p = this.$store.state.user.apellido_p
            this.last_name_m = this.$store.state.user.apellido_m
            this.dialCode = this.$store.state.user.codigo_pais
            this.telefono_movil = this.$store.state.user.telefono_movil
        },
        async save_user_profile() {
            this.saving_user_profile = true
            let profile_pic_name = ""
            if (this.birthdate == null) {
                this.birthdate = this.$store.state.user.fecha_de_nacimiento
            }
            try {
                if (this.profile_pic != null) {
                    profile_pic_name = Date.now() + "_" + this.profile_pic.name
                    await this.upload_profile_image(profile_pic_name, this.profile_pic)
                } else {
                    profile_pic_name = this.$store.state.user.foto
                }
                let response = await axios.post(this.apiurl + '/api/clients/edit', {
                    client_id: this.$store.state.user.id,
                    nombre: this.name,
                    apellido_p: this.last_name_p,
                    apellido_m: this.last_name_m,
                    fecha_de_nacimiento: this.birthdate,
                    foto: profile_pic_name,
                    codigo_pais: this.country_code,
                    telefono_movil: this.telefono_movil

                })
                if (response.data.status == "ok") {
                    this.$store.state.user = response.data.user
                    localStorage.setItem('user', JSON.stringify(response.data.user));
                    this.saving_user_profile = false
                    this.show_edit_profile = false
                }
            } catch (err) {
                console.log(err)
            }
            this.show_edit_profile = false
        }
    },
    created() {
        this.$store.state.title = "Mi Cuenta"
    },
    computed: {
        profile_image_path() {
            if (this.$store.state.user.foto != null) {
                return process.env.VUE_APP_PROFILE_IMG_PATH+this.$store.state.user.foto
            }else{
                return "/usuario.png"
            }
        }
    }
}
</script>

<style scoped>

.cc {
    font-size: 1.13em;
    margin-left: 10px;
    margin-right: -10px;
    color: black;
}

.avatar_img {
    overflow: visible
}

.bg {
    background-color: rgb(170, 170, 170);
    height: 100%;
    width: 100%; 
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
.color{
    color:blue;
    background-color: blue;
}
</style>
