import { supabase } from '../lib/supabaseClient'
export default {
    methods: {
        async upload_vehicle_image(name, file) {
            const upload_file = file
            const {
                data,
                error
            } = await supabase
                .storage
                .from('MiEPS')
                .upload('vehicle_pics/' + name, upload_file, {
                    cacheControl: '3600',
                    upsert: false
                })
                console.log(data, error)
        },
        async upload_profile_image(name, file) {
            const upload_file = file
            const {
                data,
                error
            } = await supabase
                .storage
                .from('MiEPS')
                .upload('profile_pics/' + name, upload_file, {
                    cacheControl: '3600',
                    upsert: false
                })
                console.log(data, error)
        },
    },
}